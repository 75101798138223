.pygal-chart {
    max-width: 100%;

    .tooltip-box {
        fill: $body-bg !important;
    }

    .selected .reactive {
        // Used for selected bars in fullfillment bar graph
        stroke-opacity: 1 !important;
        filter: contrast(150%) !important;
    }
}

.pygal-chart .tooltip-box {
    fill: $body-bg !important;
}

.graph-tooltip {
    position: absolute;
    top: 1rem;
    max-height: calc(100% - 20px);
    overflow-y: auto;
    background-color: $body-bg;
    visibility: hidden;
}

.graph-tooltip-contents {
    display: none;
}

.svg-container {
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
}
