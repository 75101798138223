@media print {
    * {
        --bs-primary-rgb: rgb($white);
        --bs-danger-rgb: rgb($white);
        --bs-success-rgb: rgb($white);

        box-shadow: none !important;
        color: $body-color !important;
        background-color: $body-bg !important;
    }

    body {
        max-width: none !important;
    }

    main {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    video {
        display: none;
    }

    .badge {
        border: 2px solid !important;
    }

    .container {
        max-width: none !important;
    }
    .shadow {
        box-shadow: none !important;
    }
}

iframe.activity-print-content {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border: 0;
}
