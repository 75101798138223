//
// Left side navigation
//

#sidebar {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    flex: 0 0 $sidebar-width-desktop;
    font-family: $headings-font-family !important;
    height: calc(100vh - #{$header-height-desktop});
    overflow-y: auto;
    padding: map.get($spacers, 4);
    position: sticky;
    scrollbar-width: thin;
    top: $top-navbar-height-desktop;
    transition: flex-basis .1s linear;

    .ft-current-class {
        display: flex;
        flex-direction: column;
        margin-bottom: $spacer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    &.hidden {
        flex: 0 0 3rem;
        padding: 0;

        .contents {
            display: none;
        }

        .toggle-sidebar {
            width: 100%;
            height: 100%;
            text-align: center;
            border: none;
            padding-top: map.get($spacers, 4);
            display: flex;
            flex-direction: column;
            gap: $spacer;

            .toggle-sidebar-arrow::before {
                content: unquote("\"#{ $fa-var-angles-right }\"");
            }

            .toggle-sidebar-menu-icon {
                display: block;
            }
        }
    }

    .navbar-nav {
        .nav-item {
            &.active .nav-link, .nav-link.active {
                font-weight: $font-weight-normal;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding-left: $spacer;
        padding-right: $spacer;

        .nav-item {
            @include font-size(.9rem);
        }
    }
}

.toggle-sidebar {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    @include font-size($small-font-size);
    cursor: pointer;
    color: $text-muted;
    position: absolute;
    right: 0;
    top: 0;
    padding: $spacer 0;
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
    }

    &:hover {
        background-color: $gro-lightblue;
    }

    .toggle-sidebar-arrow::before {
        content: unquote("\"#{ $fa-var-angles-left }\"");
    }

    i {
        display: block;
        padding: map.get($spacers, 2);
    }

    .toggle-sidebar-menu-icon {
        display: none;
    }
}
