.help-menu {
    .active {
        font-weight: $font-weight-bold;
        &::after {
            content: "»";
        }
    }

    ul {
        list-style-type: none;
        line-height: 2rem;
        padding: 0;

        li a {
            line-height: initial;
            display: block;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        ul {
            // That's right -- a nested UL
            @include font-size($small-font-size);
            padding-left: 1.5rem;
        }
    }
    @include media-breakpoint-up(md) {
        position: sticky;
        top: 56px;
    }
}

.help-article {
    img {
        @include box-shadow($box-shadow);
        margin: $spacer;
        max-width: calc(100% - 2rem) !important;
    }

    .watermark-container {
        position: relative;
        display: inline-block;

        .watermark {
            overflow: hidden;
            position: absolute;
            display: block;
            text-transform: uppercase;
            top: 1rem;
            left: 1rem;
            opacity: 0.1;
            font-size: 5em;
            text-align: center;
            max-height: calc(100% - 2rem);
            max-width: calc(100% - 2rem);
        }
    }
}

// Tutorial modals
#tutorial-modal {
    .modal-body {
        margin: 0;
    }

    .modal-footer {
        align-items: center;
        line-height: 1;

        .carousel-control-next {
            margin-left: auto;
        }

        .carousel-control-next-icon,
        .carousel-control-prev-icon {
            font-size: 1.5rem !important;
        }
    }
}
