@mixin striped-background {
    background-image: repeating-linear-gradient(45deg, #ccc, #fff .8px, #fff 0, #fff 50%);
    background-size: 8px 8px;
}

@mixin separator {
    display: block;
    padding-top: 5px;

    @include media-breakpoint-up(sm) {
        display: inline;
        padding-top: 0;
        margin-right: .5rem;
    }
}

@mixin fade-to-colour($colour, $height: 25%) {
    position: absolute;
    width: 100%;
    height: $height;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(rgba(255, 255, 255, 0), $colour);
}
