@use "sass:map";

header {
    box-shadow: 0 .25rem .5rem rgba(0,0,0,.1);
    background-image: url("~images/header-mobile.png");
    background-position: bottom left;
    background-repeat: no-repeat;
    display: flex;
    height: $header-height-mobile;
    position: sticky;
    top: 0;
    z-index: 3 !important;

    #header-content {
        display: flex;
        align-items: center;
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    @include media-breakpoint-up(lg) {
        background-attachment: fixed;
        background-image: url("~images/header-desktop.png");
        background-position: top center;
        height: $header-height-desktop;
        margin-bottom: -$top-navbar-height-desktop;
        top: -$header-height-desktop + $top-navbar-height-desktop;

        #header-content {
            padding-bottom: 40px;
            padding-top: 0;
        }

        #tagline {
            color: $white;
            font-family: "Gotham Bold";
            @include font-size(15px);
            line-height: 1.3;

            .sub {
                @include font-size(14px);
                margin-bottom: 10px;
            }
        }

        #header-logo-desktop {
            width: 220px;
            margin-bottom: map.get($spacers, 2);
        }
    }
}

.dev-banner {
    display: none;

    @include media-breakpoint-up(lg) {
        background-color: $danger;
        color: $white;
        display: flex;
        padding-left: .5rem;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

#top-navbar {
    align-content: flex-start;
    align-items: stretch;
    background-color: $gray-100;
    bottom: 0;
    overflow-y: auto;
    padding-top: $spacer;
    padding-bottom: $spacer;
    position: fixed;
    scrollbar-width: thin;
    top: $header-height-mobile;
    white-space: nowrap;
    word-wrap: anywhere;
    z-index: 4;

    &.show {
        width: $sidebar-width-mobile;
    }

    .navbar-nav {
        padding-left: map.get($spacers, 4);
        padding-right: map.get($spacers, 4);
        flex: 1 0 $sidebar-width-mobile;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .nav-item.active {
            text-decoration: none;
        }

        @include media-breakpoint-up(lg) {
            overflow: visible;
        }
    }

    @include media-breakpoint-up(lg) {
        background-color: unset;
        display: flex;
        flex-grow: 0;
        height: $top-navbar-height-desktop;
        justify-content: space-between;
        overflow-y: visible;
        padding: 0 $spacer 0 0;
        position: sticky;
        top: 0;

        &.show {
            width: 100%;
        }

        .navbar-nav {
            background: rgba($color: $body-bg, $alpha: 0.8);
            border-radius: 5px 5px 0 0;
            flex-direction: row;
            flex: 0 1 auto;
            padding: 0;

            &.left {
                border-top-left-radius: 0;
            }

            .nav-item {
                .nav-link {
                    color: $black;
                    padding: .5rem 1rem !important;

                    &:hover, &:focus {
                        color: $navbar-light-hover-color;
                    }

                    &.disabled {
                        color: $navbar-light-disabled-color;
                    }

                    .icon {
                        color: inherit;
                    }
                }

                &.active .nav-link {
                    border-bottom: 2px solid $black;
                    padding-bottom: 6px !important;
                    font-weight: $font-weight-normal;

                    &:hover, &:focus {
                        border-bottom-color: $navbar-light-hover-color;
                    }
                }

                .dropdown-menu {
                    max-height: calc(100vh - #{$header-height-desktop} - 1rem);
                    max-width: min(50vw, 500px);
                    min-width: 200px;
                    width: max-content;
                }
            }
        }

        .language-dropdown-container {
            display: flex;
            align-items: center;
            gap: $spacer;
            position: absolute;
            right: $spacer;
            /* Just so we don't overlap the dev-banner: */
            margin-top: 24px;
            top: -$header-height-desktop + $top-navbar-height-desktop;
            height: $header-height-desktop - $top-navbar-height-desktop - 24px;
        }
    }
}


.skolon-button-container {
    display: flex;

    &.skolon-button-mobile {
        position: absolute;
        right: 15px;

        .skolon-menu-button {
            height: 40px !important;
            width: 40px !important;
        }
    }
}
