/**
 * Basically a list-group with default rendering in xs and sm mode.
 * Use like so:
 * <div class="list-group">
 *     <div class="list-group-item responsive-list-header">
 *         <div class="row">
 *             <div class="col-3">{% translate "Name" %}</div>
 *             ...
 *         </div>
 *     </div>
 *     <div class="list-group-item">
 *         <div class="row">
 *             <div class="col-md-3 responsive-list-cell">
 *                 <div class="responsive-list-key">{% translate "Name" %}</div>
 *                 <div class="responsive-list-value">
 *                     <div>{{ first_name }}</div>
 *                     <div>{{ last_name }}</div>
 *                 </div>
 *             </div>
 *         </div>
 *     </div>
 *     ...
 * </div>
 */
 .responsive-list-header {
    display: none;
    @include font-size($small-font-size);

    .row>* {
        margin-top: auto;
    }

    @include media-breakpoint-up(md) {
        display: block;
    }
}

.responsive-list-cell {
    line-height: 1.75;

    @include media-breakpoint-up(md) {
        &:last-child .responsive-list-value {
            text-align: right;
        }
    }

    @include media-breakpoint-only(xs) {
        flex: 0 0 auto;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        gap: map.get($spacers, 2);
    }

    @include media-breakpoint-only(sm) {
        flex: 0 0 auto;
        width: 50%;

        &.sm-100 {
            width: 100%;
        }
    }
}

.responsive-list-key {
    @include font-size($small-font-size);
    margin-top: map.get($spacers, 2);
    white-space: nowrap;

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.responsive-list-value {
    @include media-breakpoint-only(sm) {
        text-align: left;
    }

    @include media-breakpoint-only(xs) {
        text-align: right;
    }
}
