footer {
    margin-top: $spacer;
    padding: $spacer;
    flex-grow: 0 !important;

    a {
        color: inherit;

        &:hover {
            color: $body-color;
        }

        &.icon-link {
            margin-right: map.get($spacers, 2);
        }
    }

    i {
        font-size: initial;
    }

    .separator {
        @include separator;
    }

    .footer-item {
        padding: 0.2rem;
    }

    &.show-app-download-banner {
        margin-bottom: 6.5rem;
    }

    @include media-breakpoint-between(sm, lg) {
        &.show-app-download-banner {
            margin-bottom: 5rem;
        }
    }
}
