.teacher-material {
    margin-top: 1rem;
    margin-bottom: 1rem;

    h1, h2 {
        color: $alt-green;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    p {
        text-align: justify;
    }

    div[data-oembed-url] {
        margin-left: -20px;
        margin-right: -20px;
    }

    .yellow-box {
        @media screen {
            background-color: $yellow;
            border-radius: 10px;
            padding: 30px;
        }
    }

    .dotted-box {
        padding: 20px;
        border: 2px dotted $black;
        margin-top: 1rem;
        margin-bottom: 1rem;
        p {
            text-align: left;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .fw-image {
        display: flex;
        flex-direction: column;

        img {
            width: 100%;
        }
    }
}
