/*
Backdrop layout and which properties to set in code:
 _____________________________________________________________
|                                                             |
|                        .top (height)                        |
|_____________________________________________________________|
|              |                                |             |
|              |                                |             |
|    .left     |           .frame               |   .right    |
| (top, width, |                                | (top, left) |
|   height)    |                                |             |
|              |                                |             |
|______________|________________________________|             |
|                                               |             |
|              .bottom (top, width)             |             |
|_______________________________________________|_____________|
*/

#floating-tutorial {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 4;

    .backdrop {
        background-color: rgba($color: $black, $alpha: $modal-backdrop-opacity);
        position: absolute;
        transition: all .1s ease-out;

        &.right {
            right: 0;
            bottom: 0;
        }
        &.bottom {
            bottom: 0;
        }
        &.top {
            top: 0;
            width: 100%;
        }
    }

    .frame {
        border: 2px solid $gray-800;
        position: absolute;
        transition: all .1s ease-out;
        z-index: 5;
    }

    .close {
        position: absolute;
        right: -1px;
        top: -1px;
        width: map.get($spacers, 5);
        height: map.get($spacers, 4);
        padding: 0;
        border-radius: 0 map.get($spacers, 1) 0 map.get($spacers, 1);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .popup {
        background: $white;
        border-radius: map.get($spacers, 1);
        border: 1px solid $gray-800;
        display: flex;
        flex-direction: column;
        margin-top: map.get($spacers, 2);
        max-width: 90%;
        min-width: 90%;
        opacity: 0;
        overflow: hidden;
        padding: $spacer;
        position: absolute;
        transition: all .1s ease-out;

        &.above {
            margin-top: 0;
            margin-bottom: map.get($spacers, 2);
        }

        .text {
            margin-bottom: $spacer;

            &>*:last-child {
                margin-bottom: 0;
            }
        }

        .indicators {
            display: flex;
            margin-bottom: $spacer;
            gap: map.get($spacers, 2);

            &>* {
                height: 8px;
            }
        }

        @include media-breakpoint-up(sm) {
            max-width: 75%;
            min-width: 400px;
            padding: map.get($spacers, 4);
        }

        @include media-breakpoint-up(lg) {
            max-width: 500px;
        }

    }

    .arrow {
        background: $white;
        border-left: 1px solid $gray-800;
        border-top: 1px solid $gray-800;
        height: 10px;
        margin-top: 3px;
        opacity: 0;
        position: absolute;
        rotate: 45deg;
        transition: all .1s ease-out;
        width: 10px;

        &.above {
            rotate: 225deg;
            margin-top: 0;
            margin-bottom: 3px;
        }
    }
}

#ft-nav-item-my-classes, #ft-nav-item-help {
    padding-left: map.get($spacers, 2);
    padding-right: map.get($spacers, 2);
    margin-left: map.get($spacers, 2) * -1;
    margin-right: map.get($spacers, 2) * -1;

    @include media-breakpoint-up(lg) {
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
    }
}
