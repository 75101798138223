// Common stylings for navbars.
// Custom desktop stylings for header navbar is in _header.scss.

body.navbar-open {
    @include media-breakpoint-down(lg) {
        overflow-y: hidden;
    }
}

.navbar {
    font-family: $headings-font-family;
    font-weight: $font-weight-normal;

    .navbar-collapse {
        border-bottom: $border-width solid $border-color;
    }

    .navbar-nav {
        display: flex;
        flex-direction: column;

        .nav-item {
            display: flex;
            flex-direction: column;

            &.btn, .nav-link.btn {
                // Meaning: If the .nav-item or .nav-link is a .btn, don't
                // use a separate colour for active states (hover etc).
                --bs-btn-active-bg: var(--bs-btn-bg);
                --bs-btn-hover-bg: var(--bs-btn-bg);
            }

            &.locked:not(.active) .nav-link, .nav-link.locked:not(.active) {
                color: $gray-600;

                &:hover, &:focus {
                    color: $gray-800;
                }
            }

            &.active, .nav-link.active {
                font-weight: $font-weight-bold;
                text-decoration: underline;
            }

            .nav-link {
                display: flex;
                align-items: center;
                gap: .5rem;
                flex-grow: 1;
                padding: .3rem 0;

                &:hover .icon, &.active .icon, &:focus .icon {
                    color: inherit;
                }

                &:focus {
                    color: $gray-900;
                }

                .dropdown-toggle-icon {
                    font-size: .7em;
                    margin-left: auto;

                    &::before {
                        content: unquote("\"#{ $fa-var-caret-down }\"");
                    }
                }

                &.show .dropdown-toggle-icon::before {
                    content: unquote("\"#{ $fa-var-caret-up }\"");
                }
            }

            .nav-text {
                overflow-x: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .icon {
                flex: 0 0 20px;
                text-align: center;
                width: 20px;
                margin: 0;
                color: $gray-500;
            }

            .dropdown-menu {
                flex: 1 1 auto;
                background-color: $body-bg;
                border: $dropdown-border-width solid $dropdown-border-color;
                border-radius: 0;
                margin-bottom: .75rem;
                padding: .3rem 0;
                box-shadow: none;
                width: 100%;

                &.show {
                    display: flex;
                    flex-direction: column;
                }

                .dropdown-item {
                    white-space: normal;
                    padding: .3rem .5rem;

                    p:last-child {
                        margin-bottom: 0;
                    }
                }

                .dropdown-item.active, .dropdown-item:active {
                    background-color: $blue;
                }

                .dropdown-divider {
                    margin: $dropdown-divider-margin-y 0;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        background-color: unset;

        .navbar-collapse {
            border: 0;
        }

        .navbar-nav {
            .nav-item {
                flex-wrap: nowrap;

                .dropdown-menu {
                    position: absolute;
                    overflow-y: auto;
                    padding: .5rem 0;
                    width: auto;
                    @include box-shadow($dropdown-box-shadow);

                    .dropdown-item {
                        padding: .5rem 1rem;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .flag {
            width: 32px;
            margin-right: .5rem;
        }
    }
}

#menu-toggler {
    color: $gray-800;

    &:active {
        color: $black;
    }

    .menu-toggler-icon {
        text-align: center;
        position: relative;
        width: 1em;
    }

    .menu-toggler-icon::before {
        content: unquote("\"#{ $fa-var-xmark }\"");
    }

    &.collapsed .menu-toggler-icon::before {
        content: unquote("\"#{ $fa-var-bars }\"");
    }
}

.menu-toggler-badge {
    // Notification badge
    position: absolute;
    top: -.25rem;
    right: -.5rem;
    padding: map.get($spacers, 1);
}

#navbar-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
    height: 100%;
    opacity: 1;
    position: fixed;
    top: 0;
    transition: opacity .15s ease;
    width: 100%;
    z-index: 3;

    &.fading {
        opacity: 0;
    }

    &:not(.show):not(.fading) {
        height: 0;
        width: 0;
        opacity: 0;
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }
}
