.select-component {
    --dropdown-height: 150px;
    --select-padding: 5px 10px;
    --select-width: 100%;
    cursor: default;
    display: inline-block;
    min-width: 200px;
    position: relative;
}
.select-component__presentation {
    align-items: center;
    border-radius: 4px;
    border: 1px solid grey;
    display: inline-flex;
    height: 40px;
    padding: var(--select-padding);
    width: var(--select-width);
}
.select-component__list {
    background-color: white;
    border-radius: 4px;
    border: 1px solid grey;
    display: block;
    max-height: var(--dropdown-height);
    overflow-y: auto;
    position: absolute;
    top: 42px;
    width: var(--select-width);
    z-index: 1;

    &.select-component__list--open {
        display: block;
    }

    .padlock {
        width: 24px;
    }
}
.select-component__list-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 40px;
    padding: var(--select-padding);

    &.select-component__list-item--hover {
        background-color: $gro-lightblue;
    }

    &.select-component__list-item--selected {
        background-color: $primary;
        color: $white;
    }
}
.select-component__icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}
.select-component__placeholder {
    color: #9ca2a7;
}
