.bottom-banner-container {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 3;

    &>* {
        max-width: $body-max-width;
        margin-left: auto;
        margin-right: auto;
    }
}

.app-download-banner {
    padding: .75rem .5rem;
    background-color: midnightblue;
    position: relative;
    display: flex;
    align-items: center;
    color: $light;

    a, .close-button {
        color: inherit;
    }

    .close-button {
        background-color: transparent;
        border: 0;
        @include font-size(1.5rem);
        padding: $spacer;
        position: absolute;
        display: none;  // save this for later
    }

    img {
        height: 50px;
        margin-right: 10px;
    }

    p {
        margin-bottom: 0;
    }

    .app-download-link {
        display: flex;
        margin: auto;
        align-items: center;
    }
}
