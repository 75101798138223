// Fix for modal bug: https://github.com/twbs/bootstrap/issues/25206
.list-group-item,
.list-group-item:hover {
    z-index: auto;
}

// Assumption: We use h5 in modal title
$modal-body-max-height-mobile-no-footer: calc(100vh - (#{$modal-dialog-margin} * 2) - (#{$modal-header-padding-y} * 2) - (#{$modal-content-border-width} * 2) - #{$modal-header-border-width} - (#{$h5-font-size} * #{$modal-title-line-height}));
// Assumption: We use button in footer
$modal-body-max-height-mobile: calc(#{$modal-body-max-height-mobile-no-footer} - (#{$modal-inner-padding} * 2) - #{$modal-footer-border-width} - (#{$btn-border-width} * 2) - (#{$btn-padding-y} * 2) - (#{$btn-font-size} * #{$btn-line-height}));
$modal-body-max-height-no-footer: calc(100vh - (#{$modal-dialog-margin-y-sm-up} * 2) - (#{$modal-header-padding-y} * 2) - (#{$modal-content-border-width} * 2) - #{$modal-header-border-width} - (#{$h5-font-size} * #{$modal-title-line-height}));
$modal-body-max-height: calc(#{$modal-body-max-height-no-footer} - (#{$modal-inner-padding} * 2) - #{$modal-footer-border-width} - (#{$btn-border-width} * 2) - (#{$btn-padding-y} * 2) - (#{$btn-font-size} * #{$btn-line-height}));


.modal {
    // Fix for modals inside elements with set max-width
    max-width: unset !important;

    .modal-content {
        min-height: 200px;
    }

    .modal-footer:empty {
        display: none !important;
    }

    .modal-header-button {
        color: $black;
        cursor: pointer;
        @include font-size($lead-font-size);
        line-height: 1;
        margin: -.5rem 0;
        opacity: .5;
        padding: .5rem;
        text-align: center;

        &:last-child {
            margin-right: -.5rem;
        }

        &:not(:disabled):focus, &:not(:disabled):hover {
            opacity: .75;
        }
    }

    .modal-body>p:last-child {
        margin-bottom: 0;
    }

    .modal-body .alert:first-child {
        margin-top: 0;
    }
}


[data-bs-toggle=modal] {
    cursor: pointer;
}


// Carousel inside modal (used for tutorial & voxeldata)
.carousel-modal {
    // If it's a carousel inside a scrollable modal, we want it to always be
    // max height, so the navigation controls don't jump up and down
    .modal-dialog-scrollable .modal-content {
        height: subtract(100vh, $modal-dialog-margin * 2);

        .carousel {
            height: 100%;
        }

        @include media-breakpoint-up(sm) {
            height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
        }
    }

    .carousel-control {
        cursor: pointer;
        position: absolute;
        opacity: .5;

        &:hover, &:focus {
            opacity: .9;
        }

        .carousel-control-icon {
            background: $body-bg;
            padding: $spacer map.get($spacers, 1);
        }

        .carousel-control-prev-icon {
            border-top-right-radius: map.get($spacers, 1);
            border-bottom-right-radius: map.get($spacers, 1);
        }

        .carousel-control-next-icon {
            border-top-left-radius: map.get($spacers, 1);
            border-bottom-left-radius: map.get($spacers, 1);
        }
    }

    .carousel {
        overflow: hidden;
        display: flex;

        .carousel-inner {
            .carousel-item {
                &.active {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }

                .carousel-item-contents {
                    max-height: 100%;
                }
            }
        }
    }
}


img.sad-mt {
    max-height: 100px;
}


// Message-popup styles
.message-popup-modal {
    .modal-body {
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: .75rem;
    }

    .message {
        display: flex;

        .message-icon {
            margin-right: 1rem;
            flex-grow: 0;
            margin-bottom: 0;
        }

        .message-text {
            flex-grow: 1;
        }
    }
}


.knowledgebase-modal {
    .flashcard-image {
        width: 100%;
    }
}


#debug-menu-modal {
    .flag {
        height: 1em;
        vertical-align: baseline;
    }
}
