@use "sass:color";
@use "sass:map";

/*
 * Import and customization of Bootstrap styles.
 * Don't mess with the order of the imports.
 *
 * "Variable overrides must come after our functions are imported, but before
 * the rest of the imports."
 * - https://getbootstrap.com/docs/5.3/customize/sass/#variable-defaults
 */

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here
$badge-font-size: .8em;
$blue: #007dbc;
$blue-100: #d1ecf1;
$body-color: #333333;
$border-color: rgba(0, 0, 0, 0.1);
$border-radius: .25rem;
$breadcrumb-bg: transparent;
$breadcrumb-divider: quote("»");
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-padding-y: 1rem;
$carousel-transition: none;
$code-font-size: 100%;
$dropdown-link-hover-bg: color.change($blue, $alpha: 0.3);
$dropdown-spacer: 0;
$enable-shadows: true;
$enable-validation-icons: false;
$font-family-sans-serif: Arial, "Helvetica Neue", Helvetica, sans-serif;
$headings-font-family: "Cabin", "cabin-fallback", sans-serif;
$info: $blue;
$link-hover-decoration: none;
$list-group-item-padding-y: 12px;
$min-contrast-ratio: 3.5;
$modal-backdrop-opacity: 0.5;
$modal-md: 674px;  // optimized to fit our 640px videos inside
$navbar-nav-link-padding-x: 1rem;
$navbar-padding-x: 0;
$navbar-padding-y: 0;
$navbar-toggler-focus-width: 1px;
$primary: $blue;
$table-cell-padding-x: .75rem;
$table-cell-padding-y: .75rem;
$transition-collapse-width: width .15s ease;
$yellow-100: lightyellow;
$zindex-dropdown: 1;
$zindex-modal-backdrop: 4;
$zindex-modal: 5;
$zindex-popover: 4;

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "~bootstrap/scss/variables";

// 4. Include any default map overrides here
$custom-colors: (
    "gro-lightblue": $gro-lightblue,
    "gro-lightred":  $gro-lightred,
    "alt-green":     $alt-green,
    "alt-red":       $alt-red,
    "alt-blue":      $alt-blue
);
$colors: map-merge($colors, $custom-colors);

$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 3
);

$alert-link-font-weight: $font-weight-normal;
$breadcrumb-active-color: $gray-700;
$breadcrumb-divider-color: $gray-700;
$form-text-color: $gray-700;
$modal-fade-transform: translateY(calc(-100% - $modal-dialog-margin));
$nav-link-color: $gray-800;
$nav-link-hover-color: $black;
$navbar-light-color: rgba($black, .8);
$navbar-light-toggler-border-color: rgba($black, .5);
$table-hover-bg: rgba($black, .03);
$text-muted: $gray-700;

// Configuration
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";

/*! postcss-cleaner:ignore on*/

// We don't want any of this to be purged by postcss-cleaner:
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/forms/form-check";

.card,
.list-group:not(.list-group-flush),
.btn:not(.btn-link),
.badge,
.ui-button {
    box-shadow: $box-shadow-sm;
}

.card>p:last-child {
    margin-bottom: 0;
}

.list-group {
    margin-bottom: 2em;
}

.list-group-flush .list-group-item {
    padding-left: 0;
    padding-right: 0;
}

.form-label {
    font-weight: $font-weight-bold;
}

.required>.form-label {
    &::after {
        content: "*";
        font-weight: $font-weight-bold;
        color: $danger;
        margin-left: 0.3rem;
    }
}

[data-bs-toggle=popover]:not([data-bs-trigger=manual]) {
    cursor: pointer;
}

.popover-body p:last-child {
    margin-bottom: 0;
}

.carousel {
    .carousel-control-next,
    .carousel-control-prev {
        position: relative;
        width: auto;
    }

    // Do not show default carousel prev/next icons, because they're white.
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        background-image: none;
        color: $body-color;
        font-size: 40px;
        height: auto;
        width: auto;
    }

    .carousel-indicators {
        margin-left: auto;
        margin-right: auto;
        position: relative;
        flex-grow: 1;
    }
}

.dropdown-menu {
    overflow-y: auto;
}

.dropdown-item.passive:focus, .dropdown-item.passive:hover {
    background-color: inherit;
    color: inherit;
}

.dropdown-divider {
    margin: $dropdown-divider-margin-y $dropdown-item-padding-x;

    // Hide dropdown-divider if it's the first or last child
    &:first-child, &:last-child {
        display: none;
    }
}

.dropdown-toggle::after {
    vertical-align: middle;
    margin-left: .5em;
}

.dropdown-toggle.show::after {
    transform: rotate(180deg);
}

.dropdown-toggle.btn, .nav-link.btn {
    // Meaning: If the .dropdown-toggle is a .btn, don't use a separate
    // colour for active states (hover etc).
    --bs-btn-active-bg: var(--bs-btn-bg);
    --bs-btn-hover-bg: var(--bs-btn-bg);
    --bs-btn-active-color: var(--bs-btn-color);
    --bs-btn-hover-color: var(--bs-btn-color);
}

/** A stretched-out dropdown toggler, preferably used with .btn. */
.dropdown-toggle-stretch {
    flex: 1 0 100%;
    width: 100%;
    display: flex;
    align-items: center;

    &::after {
        margin-left: auto;
    }
}

.btn-outline-dark {
    --bs-btn-border-color: var(--bs-gray-500);
}


.breadcrumb {
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: auto;

    .breadcrumb-item {
        flex-shrink: 0;
        color: $breadcrumb-active-color;
    }
}


.alert {
    display: flex;
    margin-bottom: $spacer;
    margin-top: $spacer;
    padding: 0;

    .alert-icon-left {
        align-items: center;
        border-bottom-left-radius: inherit;
        border-top-left-radius: inherit;
        display: flex;
        padding: 0 map.get($spacers, 2);
    }

    .alert-text {
        flex: 1 0 0%;
        padding: $spacer;

        :last-child {
            margin-bottom: 0;
        }
    }

    .btn-close {
        align-self: flex-start;
        position: relative;
    }

    &.alert-light {
        border-color: $border-color;
    }
}

.table thead th {
    border-top: 0;
}


// Not really bootstrap, but select2-bootstrap5-theme
.select2-container--bootstrap5 .select2-selection__clear {
    background-color: #999;
    margin-right: calc(20px + .3em);
}

// Bootstrap dropped this class because of "terrible type in general" (?), so
// it's up to us to rectify that:
.text-justify {
    text-align: justify !important;
}

.lh-md {
    line-height: 1.75 !important;
}

.input-group-append .input-group-text {
    height: 100%;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        // Define .w-lg-25, .h-md-100, etc.
        @each $prop, $abbrev in (width: w, height: h) {
            @each $size, $length in $sizes {
                .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
            }
        }

        // Define .border-sm, .border-xl-right, .border-lg-top-0, etc.
        .border#{$infix}         { border: $border-width solid $border-color !important; }
        .border#{$infix}-top     { border-top: $border-width solid $border-color !important; }
        .border#{$infix}-right   { border-right: $border-width solid $border-color !important; }
        .border#{$infix}-bottom  { border-bottom: $border-width solid $border-color !important; }
        .border#{$infix}-left    { border-left: $border-width solid $border-color !important; }

        .border#{$infix}-0        { border: 0 !important; }
        .border#{$infix}-top-0    { border-top: 0 !important; }
        .border#{$infix}-right-0  { border-right: 0 !important; }
        .border#{$infix}-bottom-0 { border-bottom: 0 !important; }
        .border#{$infix}-left-0   { border-left: 0 !important; }
    }
}

/*! postcss-cleaner:ignore off*/
