.padlock {
    cursor: pointer;

    &:hover .fa-lock, &:hover .fa-lock-open {
        color: $body-color !important;
    }

    &:not(.no-color) {
        .fa-lock {
            color: $red;
        }
        .fa-lock-open {
            color: $green;
        }
    }
}
