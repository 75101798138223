.tabs-component__list {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 0;
    }

    @include font-size(.9rem);

    @include media-breakpoint-up(lg) {
        @include font-size(1rem);
    }

    .vertical-scroll {
        position: absolute;
        padding: 10px 16px;
        display: none;
        background-color: $body-bg;
        cursor: pointer;
        color: $gray-700;

        &:active {
            color: $black;
        }

        &.show {
            display: block;
        }

        &.vertical-scroll-left {
            left: 0;
        }

        &.vertical-scroll-right {
            right: 0;
        }
    }
}

.tabs-component__tab-item {
    padding: 10px 20px;

    &.tabs-component__tab-item--active {
        border-bottom: 3px solid hsl(200, 89%, 50%);
    }
}

.tabs-component__hr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
    margin: 0 0 30px;
    position: relative;
    bottom: 1px;
}
