/**
 * Units are in em here, not rem, because we want to be able to shrink the
 * entire thing.
 */

@keyframes bounce {
    from {
        top: -.1em;
    }
    to {
        top: 0;
    }
}

@keyframes slideout {
    from {
        margin-left: -100%;
    }
    to {
        margin-left: 100%;
    }
}

.modal .preloader {
    border-radius: $modal-content-border-radius;
}

.preloader {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;

    &.show {
        display: flex;
        justify-content: center;
        align-items: center;

        &.backdrop {
            background-color: rgba($color: $black, $alpha: $modal-backdrop-opacity);
        }
    }

    &.small {
        @include font-size(.75em);
    }

    &.framed .contents {
        border-radius: 1em;
        border: 2px solid rgba($color: $black, $alpha: .6);
        box-shadow: 0 .3em .5em rgba($color: $black, $alpha: .15);
        margin: 0 1em;
    }

    &.backdrop .contents {
        background: $body-bg;
    }

    .contents {
        align-items: center;
        display: flex;
        flex: 0 1 auto;
        padding: 1em 1.5em;
        filter: sepia(.5);

        .mt {
            height: 3em;
            position: relative;
            margin-right: 1em;
            animation: bounce .5s ease-in-out infinite alternate;
        }

        .text {
            display: inline;
            margin-bottom: 0;
            @include font-size(1.5em);
        }

        .ellipsis-container {
            display: inline-block;
            vertical-align: top;
            position: relative;
            overflow-x: hidden;
            margin-left: .3em;
        }

        .ellipsis-bg {
            color: transparent;
        }

        .ellipsis {
            position: absolute;
            top: 0;
            left: 0;
            animation: slideout 1s infinite;
        }
    }
}
