.pie-chart {
    aspect-ratio: 1 / 1;
    background-color: $gray-300;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    text-align: left !important;
    vertical-align: middle;
    width: 100%;

    &.has-tooltip {
        cursor: help;
    }

    .fills {
        clip-path: inset(0 0 0 50%);
    }

    .fills,
    &.over50 .first-fill {
        border-radius: 50%;
        height: 100%;
        position: absolute;
        width: 100%;
    }

    &.over50 .fills {
        clip-path: none;
    }

    &.over50 .first-fill {
        clip-path: inset(0 0 0 50%);
    }

    &:not(.over50) .first-fill {
        display: none;
    }

    .second-fill {
        border-radius: 50%;
        box-sizing: border-box;
        clip-path: inset(0 50% 0 0);
        height: 100%;
        position: absolute;
        width: 100%;
    }

    .inner-circle {
        background-color: $body-bg;
        border-radius: 50%;
        height: 90%;
        left: 5%;
        position: absolute;
        top: 5%;
        width: 90%;
    }

    svg {
        position: absolute;

        text {
            fill: $body-color;
            font-family: $headings-font-family;
            font-size: 30px;
        }
    }
}
