#schoolclass_detail {
    .row {
        margin-left: -(map.get($spacers, 2));
        margin-right: -(map.get($spacers, 2));

        &>* {
            padding-left: map.get($spacers, 2);
            padding-right: map.get($spacers, 2);
        }
    }
}

.widget {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .button-accordion-toggle {
        padding: 1rem;
        cursor: pointer;
        margin-top: -1rem;
        margin-right: -1rem;
        margin-bottom: -1rem;
        text-align: center;
        line-height: 1em;
        flex: 0 1 auto;
    }
}

.top-area-dropdown {
    max-width: calc(100vw - 120px);
}

.student-table-offset {
    @include media-breakpoint-up(md) {
        margin-right: 13px;
    }
}
