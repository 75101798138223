@use "sass:map";

#cookie-consent-container {
    display: none;
    position: relative;

    &.show {
        display: block;
    }
}

#cookie-consent-banner {
    align-items: center;
    background-color: $blue-100;
    border-top: $border-width solid $border-color;
    display: flex;
    flex-direction: column;
    gap: $spacer;
    padding: 1.2rem;

    .cookie-consent-message {
        flex: 1 1 auto;
    }

    .cookie-consent-buttons {
        display: flex;
        flex-direction: column-reverse;
        gap: map.get($spacers, 2);
        width: 100%;

        &>a {
            flex-grow: 1;
        }
    }

    .cookie-consent-link {
        color: $body-color;
        font-weight: $font-weight-bold;
    }

    @include media-breakpoint-up(sm) {
        .cookie-consent-buttons {
            flex-direction: row;
        }
    }

    @include media-breakpoint-up(md) {
        flex-direction: row;

        .cookie-consent-buttons {
            flex-direction: column-reverse;
            width: auto;
        }

        .cookie-consent-buttons>a {
            white-space: nowrap;
        }
    }

    @include media-breakpoint-up(xl) {
        .cookie-consent-buttons {
            flex-direction: row;
        }
    }
}
