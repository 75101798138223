// Own variables
$alt-blue: #1f8a9b;
$alt-green: #608358;
$alt-red: #984419;
$body-max-width: 1680px;
$freebuilding-thumbnail-size: 100px !default;
$gro-lightblue: #eef3f8;
$gro-lightred: #f8f3ee;
$gro-red: #ff5453;
$header-height-desktop: 200px;
$header-height-mobile: 56px;
$top-navbar-height-desktop: 40px;
$sidebar-width-desktop: 300px;
$sidebar-width-mobile: 300px;
$sizes: (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
);

// Font Awesome
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts" !default;
$fa-style-family: "Font Awesome 6 Free";
