@use "sass:map";

/**
 * Used z-indexes:
 *   1: .select-component__list (custom Vue dropdown menu)
 *   2: .sticky-content (breadcrumbs, some headings, etc.)
 *   3: Header stuff, bottom banners
 *   4: #top-navbar (to make mobile version visible over bottom banners),
 *      main preloader backdrop, modal backdrop
 *   5: Modals
 */

/* Don't mess with the order of the imports. */

@import "variables";
@import "bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";

/*! postcss-cleaner:ignore on*/

@font-face {
    font-family: "cabin-fallback";
    src: local("Arial"), local("Helvetica"), local("Liberation Sans");
    font-weight: $font-weight-normal;
}

@font-face {
    font-family: "Cabin";
    src: local("Cabin"), local("Cabin-Regular"), local("Cabin Regular"),
        url("~fonts/Cabin-Regular.ttf") format("truetype");
    font-weight: $font-weight-normal;
    font-style: normal;
}

@font-face {
    font-family: "Gotham Bold";
    src: local("Gotham Bold"),
        local("Gotham-Bold"),
        url("~fonts/GothamBold.ttf") format("truetype"),
        url("~fonts/GothamBold.otf") format("opentype");
    font-weight: $font-weight-bold;
    font-style: normal;
}

// Some duplicating here, but we don't want any of this to be purged by
// postcss-cleaner:
// @import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/forms/form-check";
@import "~select2";
@import "~select2-bootstrap-5-theme/src/include-all";

@import "mixins";
@import "modal";
@import "header";
@import "navbar";
@import "sidebar";
@import "activity_timeline";
@import "graphs";
@import "cookie_consent";
@import "padlock";
@import "pie_chart";
@import "helpcenter";
@import "tabs_component";
@import "schoolclass_detail";
@import "select_component";
@import "bottom_banners";
@import "preloader";
@import "footer";
@import "floating_tutorial";
@import "responsive_list";
@import "notifications";

// Take this up when I have an easier way to switch between
// prefers-color-scheme requests:
// @import "dark_mode";
@import "teacher_material.scss";
// Put this last so it overrides stuff in the above:
@import "print";

// Because I don't want browsers to fuck up my game when I have explicitly
// requested to scroll to a certain position during load:
* {
    overflow-anchor: none;
}

/** ELEMENT-WIDE RULES ******************************************************/

body {
    min-height: 100%;
    background-color: $gro-lightblue;

    // We could do this via body:not(), but "[t]he ability to list more than
    // one selector is experimental and not yet widely supported."
    // https://developer.mozilla.org/en-US/docs/Web/CSS/:not
    &.teacher-material,
    &.cke_editable {
        height: initial;
        min-height: initial;
        background-color: $body-bg;
    }
    &.overlay-open {
        overflow: hidden;
    }
}

main {
    margin-left: auto;
    margin-right: auto;
    max-width: 1400px;
    padding: 0 $spacer;
    flex: 1 0 auto;
    width: 100%;

    &.has-sidebar {
        max-width: 100%;
    }

    section {
        margin-top: map.get($spacers, 5);
        margin-bottom: map.get($spacers, 5);

        &:not(:last-of-type):not(.no-border) {
            border-bottom: $border-width solid $border-color;
            padding-bottom: map.get($spacers, 5);
        }

        &:first-of-type {
            margin-top: 0 !important;
        }

        &:last-of-type {
            margin-bottom: 0 !important;
        }
    }
}

img {
    max-width: 100%;
    height: auto;
}

video {
    max-width: 100%;
}

sup {
    margin-left: map.get($spacers, 1);
}

a {
    text-decoration: none;
}

a:not([href]):focus {
    color: inherit;
}

dfn {
    text-decoration: underline dotted;
    cursor: help;
}

pre {
    margin-bottom: 0;
}

a:not(.btn):not(.badge):focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    outline: none;
}

button[disabled],
input[disabled],
.disabled {
    cursor: not-allowed !important;
}

a.disabled {
    color: $gray-500 !important;
}

[draggable] {
    cursor: move;
}


/** DYNAMICALLY GENERATED CLASSES *******************************************/

/**
 * A hacky little way to render tables as list (-ish) up to (and including) a
 * certain breakpoint. Used in templates/schools/school_detail.html.
 *
 * For all breakpoints _over_ the selected one, just render as a normal table.
 * Up to, and including, the selected breakpoint: render as a list instead,
 * where the "table cells" are laid out vertically and the table head is
 * hidden.
 *
 * So, `<table class="table-as-list-sm">` means: "render this table as a
 * list, without header, for all breakpoints up to and including sm".
 */
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-down($breakpoint) {
        table.table-as-list-#{$breakpoint} {
            &, tbody, tr, td {
                display: block;
            }

            td {
                padding-left: 0;
                padding-right: 0;
            }

            td:not(:last-child) {
                border: none;
                padding-bottom: 0;
            }

            thead {
                display: none;
            }
        }
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .h#{$infix}-100 {
            height: 100% !important;
        }
        .col#{$infix}-minimum {
            flex: 0 1 0;
            width: auto;
            max-width: 100%;
        }
    }
}

@include media-breakpoint-up(md) {
    .h-md-100 {
        height: 100% !important;
    }

    // TODO: Use Bootstrap lists/maps/whatever to do this for all sizes
    .col-md-minimum {
        flex: 0 1 0;
        width: auto;
        max-width: 100%;
    }
}

/** Defines .material.bg-blue-100 etc., with suitable foreground colours. */
.material {
    @each $colormap in ($blues, $indigos, $purples, $pinks, $reds, $oranges, $yellows, $greens, $teals, $cyans) {
        @each $key, $value in $colormap {
            &.bg-#{$key} {
                background-color: $value;
                color: color-contrast($value);
            }
        }
    }
    // Also .material.bg-blue, .material.bg-yellow, etc:
    @each $key, $value in $colors {
        &.bg-#{$key} {
            background-color: $value;
            color: color-contrast($value);
        }
    }
}


/** KEYFRAMES ***************************************************************/

@keyframes wiggle {
    0% {
        transform: none;
    }
    25% {
        transform: rotate(10deg);
    }
    75% {
        transform: rotate(-10deg);
    }
    100% {
        transform: none;
    }
}


/** COMMON UTILITIES ********************************************************/

.invisible {
    height: 0 !important;
    visibility: hidden;
    padding: 0 !important;
}

.badge-header {
    // A horizontal line with a little badge with a title on top of it.
    position: relative;
    line-height: 1;

    .badge {
        background-color: $body-bg;
        position: relative;
    }

    .badge-header-line {
        position: absolute;
        top: 50%;
        width: 100%;
        background-color: rgba($black, $alpha: 0.1);
        height: 1px;
    }
}

.dot {
    // Just a little filled circle.
    width: 1em;
    height: 1em;
    display: inline-block;
    border-radius: 100%;
    vertical-align: baseline;
}

a.body-color {
    color: $body-color !important;
}

.table-no-bottom-border tr:last-child td {
    border-bottom: none;
}

.small-xs {
    // Shrink the font, but only on the smallest screens
    @include media-breakpoint-down(sm) {
        @include font-size($small-font-size);
    }
}

.btn-close {
    background: transparent;
    font-family: $fa-style-family;
    @include font-size($lead-font-size);
    font-style: normal;
    font-weight: $font-weight-bold;
    height: auto;
    line-height: calc(var(--bs-body-font-size) * var(--bs-body-line-height));
    padding: $spacer !important;
    position: static !important;
    width: auto;
    z-index: auto !important;

    &::before {
        content: "#{ $fa-var-xmark }";
    }
}

.striped {
    @include striped-background;
}

.not-allowed {
    cursor: not-allowed;
}

ul.comma-separated {
    list-style-type: none;
    padding: 0;

    li {
        display: inline;

        &:not(:last-of-type)::after {
            content: ", ";
            font-weight: $font-weight-normal;
        }
    }
}

.icon {
    color: $gray-600;
}

.lead strong {
    font-weight: $font-weight-bold !important;
}

.img-cover {
    // For "cover images" (wide, flat, centered things on top of pages)
    min-height: 200px;
    object-fit: cover;
    object-position: center;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-help {
    cursor: help;
}

.input-group-icon {
    width: 3rem;
    justify-content: center;
}

.hover-underline {
    &::after {
        content: "";
        display: block;
        width: 0%;
        border-bottom: 1px solid $gray-600;
        margin-left: auto;
        margin-right: auto;
        transition: width .2s;
    }
    &:hover::after {
        width: 100%;
    }
}

/** BASIC PAGE STRUCTURE/COMMON SECTIONS ************************************/

#page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 1680px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

// Page help text block & other help text cards
.help-text {
    display: flex;
    flex-direction: row;
    position: relative;

    &:not(.collapsed) .help-text-more::before {
        content: unquote("\"#{ $fa-var-angles-up }\"");
    }

    &.collapsed > .help-text-more::before {
        content: unquote("\"#{ $fa-var-angles-down}\"");
    }

    .ellipsis {
        margin-top: $spacer;
    }

    &:not(.collapsed) .ellipsis {
        display: none;
    }

    &.collapsed .ellipsis {
        display: inline;
    }

    &.collapse-container {
        cursor: pointer;

        .help-text-content {
            min-height: map.get($spacers, 4);
            padding: 0;
            margin: $spacer;

            &:not(.show) {
                display: block !important;
                overflow: hidden;
                height: map.get($spacers, 4);

                * {
                    display: inline;
                }
            }

            &.collapsing {
                transition: none;
                height: auto;
            }
        }

        .help-text-more {
            padding: $spacer;
        }
    }

    .help-text-content {
        flex: 1 1 auto;

        p:last-of-type {
            margin-bottom: 0;
        }
    }

    // "Borrowed" from https://getbootstrap.com/docs/4.5/components/popovers/
    .arrow-top {
        position: absolute;
        top: calc(-0.5rem - 1px);
        width: 1rem;
        height: 0.5rem;
        margin: 0 0.3rem;
        left: 2rem;
        &::before,
        &::after {
            position: absolute;
            content: "";
            border-color: transparent;
            border-style: solid;
            border-width: 0 0.5rem 0.5rem 0.5rem;
        }
        &::before {
            top: 0;
            border-bottom-color: rgba($black, $alpha: 0.25);
        }
        &::after {
            top: 1px;
            border-bottom-color: $card-bg;
        }
    }
}

.breadcrumbs-container {
    position: sticky;
    top: $header-height-mobile;
    background-color: $body-bg;
    z-index: 2;

    @include media-breakpoint-up(lg) {
        top: $top-navbar-height-desktop;

        .breadcrumb {
            padding-top: map.get($spacers, 4);
        }
    }
}

.set-language-form {
    .dropdown-menu {
        min-width: 0;
    }

    .flag {
        width: 40px;
        display: inline-block;
    }
}


/** VOXELDATA/PHYSICSBUILD THUMBNAILS & CARDS *******************************/

.thumbnail-cards {
    .card {
        overflow: hidden;
        margin-bottom: map.get($spacers, 4);

        .image-container {
            padding-top: 100%;
            background-position: center;
            background-size: cover;
        }

        .card-body {
            flex: 0 0 auto;
            @include font-size(.9rem);
            padding: 1rem;
        }

        .card-text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    @include media-breakpoint-up(sm) {
        .card {
            width: 175px;
            display: flex;

            .card-body {
                height: calc(3em + 2rem);
                display: flex;
            }
        }

        .preview {
            max-height: 259px;
            overflow: hidden;
        }
    }
}

.thumbnail-large {
    max-width: 100%;
    max-height: inherit;
}

.thumbnail-miniatures {
    height: $freebuilding-thumbnail-size;
    margin-bottom: map-get($spacers, 3);
    overflow: hidden;
}

.thumbnail-miniature {
    width: $freebuilding-thumbnail-size;
    height: $freebuilding-thumbnail-size;
    box-shadow: $box-shadow-sm;
    margin-right: map-get($spacers, 2);
    margin-bottom: map-get($spacers, 2);
    object-fit: cover;
}


/** STUDENT DETAIL PAGE *****************************************************/

.student-area-list,
.student-activity-list {
    a:not(.btn) {
        color: $body-color;
        font-weight: $font-weight-bold;
    }
}

.student-detail__heading {
    background-color: $body-bg;
    line-height: 2;
    position: sticky;
    top: 99px;
    z-index: 2;
    // Avoiding thin borders visible from behind when element is sticky:
    margin-left: -$spacer;
    margin-right: -$spacer;
    padding-left: $spacer;
    padding-right: $spacer;

    @include media-breakpoint-up(lg) {
        top: 89px;
    }
}

// Collapse-enabled student room & area blocks in student_detail
.student-area-summary {
    cursor: pointer;

    &:not(.collapsed) .collapse-icon::before {
        content: unquote("\"#{ $fa-var-angle-up }\"");
    }

    &.collapsed .collapse-icon::before {
        content: unquote("\"#{ $fa-var-angle-down }\"");
    }

    .collapse-icon {
        padding: $spacer;
        margin-right: -$spacer;
    }

    .pie-chart {
        width: 44px;
        flex-shrink: 0;
    }
}


/** KNOWLEDGE BASE **********************************************************/

.knowledgebase-item-card {
    height: 250px;

    .card-body {
        overflow: hidden;
        position: relative;
    }

    .video-icon {
        @include font-size(2rem);
        color: $gray-500;
        border: 3px solid $gray-400;
        padding: 8px 24px;
        border-radius: 20%;
    }

    .fade-to-white {
        @include fade-to-colour($white, 50%);
    }
}


/** LICENSES ****************************************************************/

// License type list in form
.customer-details-card {
    &:not(.show) {
        display: none;
    }
    &.show {
        display: block;
    }
}

.customer-address {
    display: flex;
    flex-direction: column;

    textarea {
        flex-grow: 1;
        min-height: calc(6em + .75rem + 2px);
        width: 100%;
    }
}

.license-type-card {
    display: flex;
}


/** LOGIN & REGISTER PAGES **************************************************/

.login-section {
    background-color: $blue-100;
    padding: $spacer;
    margin-top: $spacer * 2;
}

// Edtest & Xedu badges on login page
.badge-row {
    & > div {
        max-width: 300px;
    }

    .badge-image {
        height: 120px;
        width: 120px;
        line-height: 120px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: map.get($spacers, 2);
    }

    a {
        color: $body-color !important;
        font-weight: $font-weight-bold !important;
        @include font-size($small-font-size);
    }
}

.register-section {
    background-color: $yellow-100;
    padding: $spacer;
}

.register-video {
    width: 100%;
    aspect-ratio: 16 / 9;
}

.remote-backend-icon {
    height: 40px;
    margin-right: map.get($spacers, 2);
}


/** STATISTICS/ANALYTICS PAGES **********************************************/

.plotly {
    text.node-label {
        text-shadow: none !important;
    }

    &.horizontal {
        // TODO: Get it to actually set .horizontal
        text.node-label {
            transform: translateY(.5rem) rotate(45deg);
        }
    }
}

#statistics-navbar {
    background-color: $body-bg;
    position: sticky;
    top: $header-height-mobile;
    z-index: 2;

    @include media-breakpoint-up(lg) {
        top: $top-navbar-height-desktop;
    }
}

.funnels {
    align-content: start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .funnel {
        align-self: start;
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;
    }

    .funnel-node {
        border: 1px solid darkgray;
        cursor: default;
        flex: 0 0 auto;
        font-size: 14px;
        line-height: 1em;
        overflow: hidden;
        position: relative;

        &.last-node {
            border-bottom-right-radius: 1.5rem;
        }
    }

    .funnel-node-label {
        background-color: rgba(0, 0, 0, .3);
        color: $white;
        display: inline-block;
        font-family: $headings-font-family;
        margin: 1.5rem .5rem;
        overflow: hidden;
        padding: .25rem;
        position: relative;
        word-break: break-all;
    }

    .funnel-node-badge {
        background: rgba(255, 255, 255, .8);
        font-size: 13px;
        font-weight: 700;
        line-height: 1em;
        margin: -1px 0 0 -1px;
        padding: 3px 5px;
        position: absolute;
        top: 0;
    }

    .funnel-node-backgrounds {
        display: flex;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &.vertical {
        flex-direction: column;
        height: 800px;

        .funnel {
            flex-direction: row;
            min-height: 4rem;
        }

        .funnel-node {
            min-height: 4rem;
            width: 100px;
        }

        .funnel-node-label {
            max-height: calc(100% - 2rem);
        }

        .funnel-node-backgrounds {
            flex-direction: column;
        }
    }

    &:not(.vertical) {
        .funnel {
            min-width: 4rem;
        }

        .funnel-node {
            min-width: 4rem;
        }

        .funnel-node-label {
            max-width: calc(100% - 1rem);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .funnel-node-background {
            height: 100%;
        }
    }
}

.sources-info {
    align-items: start;
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
}


/** "HOW LIKELY ARE YOU TO RECOMMEND" POLL **********************************/

#recommend-poll {
    bottom: 0;
    left: 50%;
    max-height: 0;
    transition: max-height .5s;
    transform: translateX(-50%);
    box-shadow: 0 0 1rem .25rem rgba(0,0,0,.075);

    &.show {
        max-height: 300px;
    }

    .btn-close {
        position: absolute !important;
        top: .5rem;
        right: 1rem;
    }

    .level-buttons {
        justify-content: space-between;

        .level-button {
            aspect-ratio: 1;
            width: 3rem;
            font-size: 1.25rem;
            font-weight: bolder;
            padding: 0;
        }
    }

    @include media-breakpoint-up(sm) {
        .level-buttons {
            gap: 1rem;

            .level-button {
                width: 4rem;
                font-size: 1.5rem;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .level-buttons {
            .level-button {
                width: 4rem;
                font-size: 1.5rem;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        max-width: 960px;

        .btn-close {
            top: .5rem;
            right: 1rem;
        }
    }
}


/** VARIOUS CLASS AND ID RULES **********************************************/

[role="button"] {
    cursor: pointer;
}

.fas, .fa-solid {
    font-weight: 900 !important;
}

// The menu in terms pages
.secondary-menu {
    @include media-breakpoint-up(md) {
        position: sticky;
        top: calc(#{$top-navbar-height-desktop} + #{$spacer});
    }
}

// collapse
.collapse-caret {
    width: 10px;
}

:not(.collapsed) > .collapse-caret::before {
    content: unquote("\"#{ $fa-var-caret-down }\"");
}

.collapsed > .collapse-caret::before {
    content: unquote("\"#{ $fa-var-caret-right }\"");
}


.classlist-icons {
    i {
        margin-right: 5px;
    }

    .classlist-students-icon {
        width: 3rem;
        display: inline-block;
    }
}

// Student list in schoolclass_form.html
#student-list .new .hide-for-new {
    display: none !important;
}


// Finished exams on class detail page
.finished-exams {
    &.narrow {
        @include media-breakpoint-up(xl) {
            .col {
                flex-grow: 1;
            }
        }
    }
}

.select-label {
    // Used for SelectComponent selects
    @include font-size($sub-sup-font-size);
    display: inline-flex;
    position: absolute;
    top: -20px;
}

// Separate input groups in "invite users" modal, but only on < lg screens:
.invite-users-list {
    .row:last-child hr {
        display: none;
    }
}

#exercise-room-detail .select-component {
    width: 100%;
    max-width: 500px;
}

.horizontal-bar-graph {
    // Is technically a table. Used on class dashboard & statistics pages
    .locked {
        opacity: .5;
    }

    td {
        vertical-align: middle;
        width: 99%;
    }

    col.name {
        width: 40%;
    }

    .bar-background {
        height: 10px;
        background-color: $gray-300;
        position: relative;
    }

    .bar-secondary-progress {
        height: 10px;
        position: absolute;
        top: 0;
        background-color: blue;
    }

    .bar-progress {
        height: 10px;
        background-color: $success;
        position: absolute;
        top: 0;
        cursor: help;
    }

    @include media-breakpoint-up(md) {
        col.name {
            width: unset;
        }
        th {
            white-space: nowrap;
        }
    }
}

.news-item {
    p:last-child {
        margin-bottom: 0;
    }
}

#logstream-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: $spacer 0;
    gap: $spacer;

    #stream-container {
        font-size: 12px;
        font-family: $font-family-monospace;
        padding: map.get($spacers, 2);
        flex-grow: 1;
        overflow-y: scroll;

        .log-record {
            &:first-child hr {
                display: none;
            }

            .badge {
                font-size: 10px;
            }
        }
    }
}

#FlamegraphPanel .djDebugPanelContent {
    padding: 0;

    .djdt-scroll {
        padding: 0;
        overflow: visible;
    }
}

/*! postcss-cleaner:ignore off*/
