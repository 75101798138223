.timeline {
    position: relative;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        display: block;
    }

    @include media-breakpoint-up(xxl) {
        width: 800px;
    }

    .left-line-container {
        width: 30px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        position: absolute;
        top: 0;
        bottom: 0;
        text-align: center;

        @include media-breakpoint-up(md) {
            width: 50%;
            padding-right: 2rem;
        }

        .left-line {
            width: 1px;
            background-color: silver;
            height: 100%;
            display: inline-block;
        }
    }

    .activity-container {
        margin-top: 1rem;
        margin-bottom: 1rem;
        position: relative;

        &:not(.start):not(.end) {
            padding-right: 1rem;
        }

        &.offline {
            padding-left: 2rem;
        }

        @include media-breakpoint-up(md) {
            width: 50%;

            &:not(.offline) {
                float: left;
                clear: both;
                padding-right: 2rem !important;
            }

            &.offline {
                float: right;
                clear: right;
                padding-right: 0 !important;
            }
        }
    }

    .arrowhead {
        width: 30px;
        position: absolute;
        top: -1rem;
        text-align: center;

        i {
            color: silver;
        }

        @include media-breakpoint-up(md) {
            width: 100%;
            padding-right: 2rem;
        }
    }
}


.activity-cards {
    &.locked {
        .activity-card.start {
            background-color: $gro-red !important;
        }

        .activity-card:not(.start):not(.end), .activity-card-percentage-circle {
            @include striped-background;
        }

        .btn-lock {
            @include button-variant($success, $success);
        }
    }

    &:not(.locked) .btn-lock {
        @include button-variant($danger, $danger);
    }
}


.activity-card {
    min-height: 102px;
    width: 100%;

    &.start {
        color: $white;
        background-color: $green;
    }

    &.end {
        color: $white;
        background-color: $orange-600;
    }

    &:not(.start):not(.end) {
        cursor: pointer;
    }

    &.offline {
        background-color: $gro-lightred;
    }

    @include media-breakpoint-up(sm) {
        min-width: 240px;
    }

    .btn {
        cursor: pointer;
    }

    .card-body {
        position: relative;

        .card-title {
            margin-bottom: .5rem;
        }
    }

    .voluntary-badge {
        // Actually not for the "voluntary activities" section, but for
        // offline activities
        position: absolute;
        top: -8px;
        right: -5px;
        border: 1px solid $card-border-color;
        background-color: $gro-lightblue;
        padding: 0 5px;
        border-radius: $border-radius;
    }

    .activity-card-percentage-circle {
        width: 50px;
        height: 50px;
        line-height: 50px;
        background-color: $card-bg;
        background-clip: border-box;
        border: 1px solid $card-border-color;
        border-radius: 100%;
        position: absolute;
        top: -20px;
        right: -20px;
        @include font-size(.8rem);
        font-family: $headings-font-family;
        font-weight: $font-weight-normal;
        cursor: pointer;
        box-shadow: $box-shadow-sm;
    }

    .activity-card-percentage-circle.gold {
        background-color: hsl(51, 100%, 75%);
    }
}

.activity-cards.voluntary {
    border: 1px dashed $gray-500;
    border-radius: 10px;
    margin-top: 1.5rem;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 1rem 0 0;

    .voluntary-title {
        position: absolute;
        top: -.7rem;
        left: 1rem;
        background-color: $body-bg;
        border: 1px dashed $gray-500;
        color: $body-color;
    }
}

.finished-percentage {
    height: 100%;
    top: 0;
    background-color: $green;
    position: absolute;
    opacity: 0.2;
}
