/**
 * .notification should generally not be treated as a place to add styles in
 * and of itself. This class is used to inject javascript here and there, and
 * is used in multiple different contexts (dropdown menus, alerts, modals).
 * To style these different contexts, nestle the .notification selector
 * inside e.g. ".notification-dropdown-item" or ".notification-alert".
 */
 .notification {
    // OK, these are exceptions:
    .notification-link {
        cursor: pointer;
    }

    .notification-text p:last-child {
        margin-bottom: 0;
    }
}

.notification-dropdown-item {
    .notification {
        .notification-metadata {
            @include font-size($small-font-size);
            font-weight: $font-weight-bold;
        }

        &.seen .notification-text {
            color: $text-muted;
        }

        .notification-dismiss {
            display: none;
        }
    }
}

.notification-alert {
    .notification {
        display: flex;
        flex: 1 0 0%;

        .notification-metadata {
            @include font-size(.8rem);
        }

        .notification-text {
            padding: $spacer;
            flex: 1 0 0%;
        }
    }
}

.notification-badge {
    font-size: .75rem !important;
    font-family: $headings-font-family;
}

.notification-bell {
    display: inline-block;

    .filled-icon {
        animation: .2s 4 ease-in-out wiggle;
    }
}
